import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest, findIn} from "@elements/dom-utils";


export function init() {
    onFind('.js-hotspot__toggle', function (hotspotButton) {
        on('click', function () {
            let container = closest('.js-hotspot-container', hotspotButton);
            let hotspot = closest('.js-hotspot', hotspotButton);
            let detail = findIn('.js-hotspot__detail', hotspot);
            let closeBtn = findIn('.js-hotspot__close', detail);

            if(!hasClass('is-open', hotspot)) {
                addClass('is-open', hotspot);

                window.addEventListener("click", function _listener(evt) {
                    if (!hotspot.contains(evt.target)) {
                        removeClass('is-open', hotspot);

                        window.removeEventListener("click", _listener, true);
                    }
                }, true);


                //set detail position
                removeClass('hotspot--detail-left', hotspot);

                let offset = 25,
                    detailHeight = detail.getBoundingClientRect().height,
                    posTop = hotspot.getBoundingClientRect().top - container.getBoundingClientRect().top;

                if (matchMedia('(max-width: 767px)').matches) {
                    if (hotspot.getBoundingClientRect().top < detailHeight) {
                        //position bottom
                        detail.style.top = hotspot.getBoundingClientRect().top + hotspot.getBoundingClientRect().height + 'px'
                    }else{
                        //position top
                        detail.style.top = hotspot.getBoundingClientRect().top - detailHeight - offset + 'px'
                    }
                }

                console.log(detail.getBoundingClientRect().left);
                console.log(detail.getBoundingClientRect().width);
                console.log(container.getBoundingClientRect().width);
                console.log(container.getBoundingClientRect().width);

                //if overflow right
                if(detail.getBoundingClientRect().left < 50) {
                    addClass('hotspot--detail-left', hotspot);
                }

                on("click", function () {
                    removeClass('is-open', hotspot);
                }, closeBtn);

            } else {
                removeClass('is-open', hotspot);
            }

        }, hotspotButton);
    });
}