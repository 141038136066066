import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,addClass,on,findIn, findAllIn, hasClass} from '@elements/dom-utils';
import Popover from 'bootstrap/js/dist/popover';

const selectors = {
    base: '.js-service-popup',
    heroBtn: '.js-service-popup__btn',
    stickyBtn: '.js-service-popup__btn-sticky',
    toggleBtn: '.js-service-popup__toggle',
    closeBtn: '.js-service-popup__btn-close',
    navigateBtn: '.js-service-popup__btn-navigate',
    backBtn: '.js-service-popup__btn-back',
    content: '.js-service-popup__content',
    contentService: '.js-service-popup__content-service',
    contentCustomerService: '.js-service-popup__content-customer-service',
    contentRequestsService: '.js-service-popup__content-requests-service',
    title: '.js-service-popup__title'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        //popover btns
        let heroBtn = findIn(selectors.heroBtn, document.body);
        let stickyBtn = findIn(selectors.stickyBtn, document.body);
        let toggleBtn  = findAllIn(selectors.toggleBtn, document.body);
        //btns inside popover
        let closeBtn = findIn(selectors.closeBtn, baseElement);
        let navigateBtn = findAllIn(selectors.navigateBtn, baseElement);
        let backBtn = findIn(selectors.backBtn, baseElement);
        //popover content
        let title = findIn(selectors.title, baseElement);
        let content= findIn(selectors.content, baseElement);
        let contentService= findIn(selectors.contentService, baseElement);
        let contentCustomerService= findIn(selectors.contentCustomerService, baseElement);
        let contentRequestsService= findIn(selectors.contentRequestsService, baseElement);

        let offsetY = -15;
        let offsetX = -170;
        let placement = 'top';
        if (window.innerWidth < 767) {
            offsetY = 15;
            offsetX = 0;
            placement = 'top';
        }

        toggleBtn.forEach((button) => {
            const popover = new Popover(button, {
                html: true,
                trigger: 'manual',
                title: title,
                content: content,
                placement: placement,
                customClass: 'service-popover',
                fallbackPlacements: ['top', 'bottom'],
                offset: [offsetX, offsetY],
                delay: {
                    "show": 100,
                    "hide": 100
                },
            });

            on('click', function () {
                if(!hasClass('is-open', button)) {
                    addClass('is-open', button);
                    showContent(contentService, title);
                    popover.show();

                    on('click', function(evt) {
                        removeClass('is-open', button);
                        popover.hide();
                        hideContent(contentService, title, [contentCustomerService, contentRequestsService], backBtn);
                    }, closeBtn)
                } else {
                    popover.hide();
                    removeClass('is-open', button);
                    hideContent(contentService, title, [contentCustomerService, contentRequestsService], backBtn);
                }

            }, button);

            navigateBtn.forEach((button) => {
                let next = button.getAttribute('data-next');
                let current = button.getAttribute('data-current');


                on("click", function(){
                    if (next === "customer-service") {
                        contentCustomerService.removeAttribute('hidden');
                        contentService.setAttribute('hidden', 'true');
                        content.setAttribute('data-current', next);
                        backBtn.setAttribute('data-back', current);
                        handleBack(backBtn, title);
                    }else if(next === "requests-service"){
                        contentRequestsService.removeAttribute('hidden');
                        contentService.setAttribute('hidden', 'true');
                        content.setAttribute('data-current', next);
                        backBtn.setAttribute('data-back', current);
                        handleBack(backBtn, title);
                    }
                }, button)
            });

            handleBackClick(backBtn, contentService, contentCustomerService, contentRequestsService, title)
        })

        if (heroBtn){
            handleStickyButton(heroBtn, stickyBtn)

            //check if btn is in viewport - let sticky btn appear/disappear
            on('scroll', function() {
                handleStickyButton(heroBtn, stickyBtn)
            }, document)
        }else{
            addClass('service-popup__btn-sticky--visible', stickyBtn)
            removeClass('service-popup__btn-sticky--hidden', stickyBtn);

            //check if btn is in viewport - let sticky btn appear/disappear
            on('scroll', function() {
                handleStickyButton(heroBtn, stickyBtn)
            }, document)
        }
    });
}

function isVisible (button) {
    if (button){
        const { top, bottom } = button.getBoundingClientRect();

        /*add to check if element is in viewport
        const viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
        top < viewportHeight

        check if element is in/above the viewport*/
        return (
            (top > 0 && bottom > 0)
        );
    }
}

function handleStickyButton(heroBtn, stickyBtn){
    let triggerEl = document.getElementsByClassName('js-service-popup__btn--hero')[0];
    let myPopover = Popover.getInstance(triggerEl);

    let scrolledTo = window.scrollY + window.innerHeight;
    let threshold = 350;
    let isReachBottom = document.body.scrollHeight - threshold <= scrolledTo;

    if (isVisible(heroBtn) || isReachBottom) {
        addClass('service-popup__btn-sticky--hidden', stickyBtn);
        removeClass('service-popup__btn-sticky--visible', stickyBtn);
    }else{
        //hide myPopover if it's open
        if (myPopover){
            myPopover.hide();
            removeClass('is-open', triggerEl);
        }
        removeClass('service-popup__btn-sticky--hidden', stickyBtn);
        addClass('service-popup__btn-sticky--visible', stickyBtn)
    }
}

function showContent(startContent, title){
    startContent.removeAttribute('hidden')
    title.removeAttribute('hidden');
    addClass('d-flex', title);
}

function hideContent(startContent, title, contents, backBtn){
    startContent.setAttribute('hidden', true)
    title.setAttribute('hidden', true);
    backBtn.setAttribute('data-back', '');
    handleBack(backBtn, title)

    contents.forEach(content => {
        console.log(content)
        content.setAttribute('hidden', true)
    })
}

function handleBack(btnBack, title){
    if (btnBack.getAttribute('data-back')){
        btnBack.removeAttribute("hidden")

        addClass('justify-content-between', title)
        removeClass('justify-content-end', title)
    }else{
        btnBack.setAttribute("hidden", "true")

        addClass('justify-content-end', title)
        removeClass('justify-content-between', title)
    }
}

function handleBackClick(btn, contentService, contentCustomerService, contentRequestsService, title){
    on('click', function(evt){
        evt.preventDefault()
        if (btn.getAttribute('data-back') === "customer-service") {
            contentCustomerService.removeAttribute('hidden');

            contentRequestsService.setAttribute('hidden', 'true');
            contentService.setAttribute('hidden', 'true');
        }
        if(btn.getAttribute('data-back') === "requests-service"){
            contentRequestsService.removeAttribute('hidden');

            contentCustomerService.setAttribute('hidden', 'true');
            contentService.setAttribute('hidden', 'true');
        }
        if(btn.getAttribute('data-back') === "service"){
            contentService.removeAttribute('hidden');
            btn.setAttribute('data-back', '');

            contentCustomerService.setAttribute('hidden', 'true');
            contentRequestsService.setAttribute('hidden', 'true');
        }

        handleBack(btn, title)
    }, btn)
}