import {onFind} from "@elements/init-modules-in-scope";
import {on, addClass, removeClass, findIn, findAllIn} from "@elements/dom-utils";

export function init() {
    onFind(".js-file-upload", function(fileUpload) {
        let input = findIn("input[type=file]", fileUpload);
        let multiple = input.getAttribute("multiple");

        checkFileInput(input)

        on("change", function(){
            if (multiple) {
                //remove old delete btn when adding new files
                let deleteBtnsOld = findAllIn('.js-file-upload__delete', fileUpload);
                deleteBtnsOld.forEach((btn) => {
                    btn.parentElement.remove();
                })

                let files = Array.from(input.files);
                let filesContainer = findIn(".js-file-upload__results", fileUpload);

                //generate delete btns
                files.forEach((file, index) => {
                    let btn = '<li class="list-inline-item delete-file__item"><button class="btn delete-file__btn btn-icon-text btn-icon-text--right js-file-upload__delete" type="button" data-file-id="' + index + '">' + file.name + '<span class="icon icon-bin icon-primary"></span></button></li>';
                    filesContainer.insertAdjacentHTML("beforeend", btn);
                })

                let deleteBtns = findAllIn('.js-file-upload__delete', fileUpload);
                deleteBtns.forEach((btn) => {
                    on("click", function(){
                        removeFile(btn, input, files);
                    }, btn)
                })

            }else{
                //single file upload
                checkFileInput(input)
            }
        }, input)
    })
}

function checkFileInput (input){
    if (input.value !== "") {
        removeClass("file-upload__empty-input", input)
    }else{
        addClass("file-upload__empty-input", input)
    }
}

function removeFile(btn, input, files){
    let id = btn.getAttribute("data-file-id");
    let dt = new DataTransfer();

    for (let i = 0; i < files.length; i++) {
        let file = files[i];

        //only add file to filelist if it's not the file to be removed
        if (id !== i.toString()) {
            dt.items.add(file); // here you exclude the file. thus removing it.
        }

        //add file to filelist
        input.files = dt.files;
    }

    //delete btn
    btn.parentElement.remove()
}
