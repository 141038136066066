"use strict";

import {getPrefixedDataSet} from "@elements/data-set-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {insertAdjacentHTML, findIn, on, findAllIn, setAttribute} from "@elements/dom-utils";
import * as formValidation from "@elements/form-validation";

const defaultSelectors = {
    form: '.js-reload-form',
    loading: '.js-reload-form__loading'
};

const defaultOptions = {
    validator: () => true,
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.form, function (baseElement) {
        let validation = baseElement.getAttribute('data-reload-validation');

        if (validation) {
            let formInstance;

            formValidation.getApi(baseElement).then((formApi) => {
                formInstance = formApi.getFormInstance();

                formInstance.on('core.form.valid', () => {
                    if (typeof options.validator === "function" && options.validator(baseElement)) {
                        handleFormSubmit(baseElement, selectors);
                    }
                });
            });
        }else{
            createReloadForm(
                baseElement,
                {...defaultOptions, ...options},
                {...defaultSelectors, ...selectors}
            );
        }
    });
}

export function createReloadForm(reloadForm, options = defaultOptions, selectors = defaultSelectors) {
    on('submit', function (evt) {
        if (typeof options.validator === "function" && options.validator(reloadForm)) {
            handleFormSubmit(reloadForm, selectors, evt.submitter);
        }
    }, reloadForm);
}

function handleFormSubmit(form, selectors, submitButton) {
    let loading = findIn(selectors.loading, form);
    if (loading) {
        loading.removeAttribute('hidden');
    }

    let dataSet = getPrefixedDataSet('reload-form', form);

    if (dataSet.disableButton !== "false") {
        let btnToDisable = findAllIn('button[type="submit"], button:not([type])', form);
        btnToDisable.map(setAttribute('disabled', true));

        if (submitButton && submitButton.getAttribute('name')) {
            insertAdjacentHTML('beforeend', `
                <input type="hidden" name="${submitButton.getAttribute('name')}" value="${submitButton.getAttribute('value')}">
            `, form);
        }
    }
}
