"use strict";
import {on, find, findAllIn, findIn, empty} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import axios from "axios";

export function init() {
    onFind('.js-dynamic-modal-toggle', function (element) {
        let modal = find(element.getAttribute('data-bs-target'));
        let method = element.getAttribute('data-content-method') || 'POST';

        on('click', function () {
            loadContent(modal, element.getAttribute('data-content-url'), method);
        }, element);
    });

    onFind('#dynamicModal', function (modal) {
        on('hidden.bs.modal', function () {
            //find js-dynamic-modal__content and empty it
            let content = findIn('.js-dynamic-modal__content', modal);
            content.innerHTML = '';
        }, modal);
    });
}

export function loadContent(modal, url, method, data) {
    let pendingRequest;

    if (method === 'GET') {
        pendingRequest = axios({method: 'GET', url: url, params: data });
    } else {
        pendingRequest = axios({method: 'POST', url: url, data: data });
    }

    asyncAppend({
        target: findAllIn('.js-dynamic-modal__content', modal),
        loading: findAllIn('.js-dynamic-modal__loading', modal)
    }, pendingRequest.then((x) => x.data));

    return pendingRequest;
}