import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,addClass,on,findIn, findAllIn, hasClass} from '@elements/dom-utils';

const selectors = {
    base: '.js-header',
    //hero: '.hero'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let header = findIn(selectors.base, document.body);
        /*let hero = findIn(selectors.hero, document.body);*/

        //add class to header on scroll
        window.addEventListener('scroll', function(){

            //check if hero is in viewport
            /*window.addEventListener('scroll', function(){
                //check if hero is in viewport
                let rect = hero.getBoundingClientRect();
                if (rect.bottom <= 150) {
                    removeClass('header--transparent', header);
                } else {
                    addClass('header--transparent', header);
                }
            });*/


            if (window.scrollY > 120){
                removeClass('header--transparent', header);
            }
            if (window.scrollY === 0){
                addClass('header--transparent', header);
            }
        });
    });
}
