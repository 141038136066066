import Autocomplete from '../libs/autocomplete.esm.js';
import {initInScope, onFind} from "@elements/init-modules-in-scope";
import {translate} from '@elements/translations';
import axios from "axios";
import {closest, findAllIn, findIn, on} from "@elements/dom-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import {renderTemplate} from "./render-template";
import {loadContent} from "./dynamic-modal";

const selectors = {
    base: '.js-autocomplete',
    item: '.js-autocomplete__item'
};

// the input with the base class un-/checks all other inputs with the child class that are in the container
export function init() {
    /* init autocomplete for multiple search */
    let autocompleteElements = findAllIn(selectors.base, document.body);
    autocompleteElements.forEach(autocompleteElement => {

        let remoteUrl = autocompleteElement.dataset.autocompleteRemoteUrl;
        let categories = JSON.parse(autocompleteElement.dataset.autocompleteCategories);
        let id = autocompleteElement.id;

        loadAutocomplete(remoteUrl, categories, id);
    });


    /* set data etc. for customers */
    onFind('.js-autocomplete__item--set-autocomplete-id', function (item) {
        on('click', function (event) {
            event.preventDefault();
            let autocomplete = closest('.js-autocomplete-wrapper', item);
            let input = findIn('.js-set-autocomplete-id__input', autocomplete);
            let textString = findIn('.js-set-autocomplete-id__string', autocomplete);
            let id = item.getAttribute('data-id');

            if (input) {
                input.value = id;
            }
            if (textString) {
                textString.innerHTML = id;
            }
        }, item);
    });
}

export function loadAutocomplete(remoteUrl, categories, id) {
    let wildcard = "%QUERY%";

    new Autocomplete(id, {
        clearButton: false,
        onSearch: ({ currentValue }) => {
            const api = remoteUrl.replace(wildcard, encodeURIComponent(currentValue));
            return axios.get(api)
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
        },
        onResults: ({ currentValue, matches, template, classGroup }) => {

            let items = [];

            // loop through the categories and create the result items
            categories.map((category) => {
                let categoryItems = matches[category.name];

                if (categoryItems) {
                    let tempItems = '';

                    if (categoryItems) {
                        tempItems = categoryItems.map((data, index, array) => {
                            // create the regex for the highlight
                            let regexp = new RegExp(currentValue, 'gi');
                            if (data.title && data.setInputId) {
                                return `<button data-id="${data.id}" class="autocomplete__suggestion autocomplete__selectable js-autocomplete__item js-autocomplete__item--set-autocomplete-id">
                                            <strong>${data.title.replace(regexp, '<mark>$&</mark>')}</strong>
                                            ${data.text ? "<div class='autocomplete__suggestion-text'>" + data.text.replace(regexp, '<mark>$&</mark>') + "</div>" : ""}
                                        </button>`
                            } else {
                                return `<a href="${data.url}" class="autocomplete__suggestion autocomplete__selectable js-autocomplete__item js-autocomplete__item--url">
                                            <strong>${data.title.replace(regexp, '<mark>$&</mark>')}</strong>
                                            ${data.text ? "<div class='autocomplete__suggestion-text'>" + data.text.replace(regexp, '<mark>$&</mark>') + "</div>" : ""}
                                        </a>`
                            }
                        }).join('');
                    }

                    let categoryOverviewUrl = "";
                    if (category.overviewUrl) {
                        categoryOverviewUrl = category.overviewUrl
                            + (category.overviewUrl.indexOf('?') >= 0 ? '&' : '?')
                            + 'searchTerm=' + currentValue;
                    }

                    let header = '';

                    if (category.title) {
                        header = `<div class="autocomplete__dataset-header">${category.title}</div>`;
                    }
                    let footer = '';

                    // search Suggestions are shown in the left area
                    if (tempItems) {
                        if (category.overviewUrl) {
                            footer = `<div class="autocomplete__dataset-footer">
                                       <a href="${categoryOverviewUrl}" class="autocomplete__show-more-btn">${translate('autocomplete.show-more')}</a>
                                  </div>`;
                        }

                        items.push(`<div class="autocomplete__dataset">${header}${tempItems}${footer}</div>`);
                    }
                }
            }); // end of categories.map

            // checking if we have results if we don't
            // take data from the noResults callback

            return matches === 0
                ? template
                : `<li class="autocomplete__results">
                        <div class="autocomplete__item">${items.join('')}</div>
                    </li>`;
        },
        onOpened: ({results}) => {
            initInScope(results);
        },
    });
}