import {onFind} from "@elements/init-modules-in-scope";
import {on, closest, findAllIn, trigger} from "@elements/dom-utils";

export function init () {
    onFind('.js-video__play', function (playButton) {
        on('click', function (event) {
            event.preventDefault();
            let container = closest('.js-video', playButton);
            let video = findAllIn('.js-video__video', container);
            let loading = findAllIn('.js-video__loading', container);
            let overlay = findAllIn('.js-video__overlay', container);
            let type = video[0].getAttribute('data-type');
            let src = video[0].getAttribute('data-src');

            loading[0].removeAttribute('hidden');

            video[0].src = src;

            if (type === 'asset') {
                video[0].play();
                video[0].oncanplay = function() {
                    loading[0].remove();
                };
            } /*else {
                video[0].src += '?autoplay=1&rel=0';
                video[0].onload = function() {
                    loading[0].remove();
                };
            }*/

            overlay[0].remove();
            playButton.remove();
        }, playButton);
    });
}