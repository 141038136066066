import {onFind} from "@elements/init-modules-in-scope";
import {findIn, findAllIn, hasClass, addClass, removeClass, on} from "@elements/dom-utils";

const selectors = {
    base: '.js-category-list',
    level1: '.js-category-list-level-1',
    level2: '.js-category-list-level-2',
    level3: '.js-category-list-level-3',
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let level1 = findAllIn(selectors.level1, baseElement);
        let level2 = findAllIn(selectors.level2, baseElement);
        let level3 = findAllIn(selectors.level3, baseElement);

        level1.forEach((element) => {
            let btn = findIn('.js-category-list__btn', element);

            on('click', function (lvl1) {
                lvl1.preventDefault()

                if (hasClass( 'is-open', element)) {
                    removeClass( 'is-open', element);

                    level2.forEach((element) => {
                        if (hasClass('is-open', element)) {
                            removeClass('is-open', element);
                        }
                    })
                } else {
                    addClass( 'is-open', element);
                }
            }, btn);
        });

        level2.forEach((element) => {
            let btn = findIn('.js-category-list__btn', element);

            on('click', function (lvl2) {
                lvl2.preventDefault()

                if (hasClass('is-open', element)) {
                    removeClass('is-open', element);
                } else {
                    addClass('is-open', element);
                }
            }, btn)
        })
    });
}