import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest} from "@elements/dom-utils";

let backdrop = document.querySelectorAll('.js-backdrop')[0];
let sidenav = document.querySelectorAll('.js-side-nav')[0];
let level1Link = document.querySelectorAll('.js-side-nav__level-1-link');
let navLevels = document.querySelectorAll('.js-side-nav__level');
let sideItem = document.querySelectorAll('.js-side-item');
let sideitemToggle = document.querySelectorAll('.js-side-item__toggle');

export function init() {
    /* nav toggle opens sidenav */
    onFind('.js-side-nav__main-toggle', function (button) {
        on('click', function () {
            /* close side items */
            sideitemToggle.forEach(function (toggler) {
                removeClass('open', toggler);
            });
            sideItem.forEach(function (item) {
                removeClass('open', item);
            });


            if(!hasClass('open', button)) {
                addClass('open', button);
                addClass('open', sidenav);
                addClass('open', backdrop);
            } else {
                removeClass('open', button);
                removeClass('open', sidenav);
                removeClass('open', backdrop);
            }

            navLevels.forEach(function (navLevel) {
                if(hasClass('open', navLevel)) {
                    removeClass('open', navLevel);
                }
            });

            level1Link.forEach(function (el) {
                if(hasClass('has-openend-sidenav', el)) {
                    removeClass('has-openend-sidenav', el);
                }
            });
        }, button);
    });


    /* nav level toggle */
    onFind('.js-side-nav__link', function (link) {
        on('click', function () {
            //get attribute data-side-nav-level and open the level
            let level = link.getAttribute('data-side-nav-level');
            let levelEl = document.querySelectorAll('.js-side-nav__level[data-side-nav-level="' + level + '"]')[0];
            addClass('open', levelEl);
        }, link);
    });


    /* main nav link */
    onFind('.js-side-nav__level-1-link', function (link) {
        let sidenavLevels = document.querySelectorAll('.js-side-nav__level');
        let mainNavToggler = document.querySelectorAll('.js-side-nav__main-toggle')[0];
        let level = link.getAttribute('data-side-nav-level');
        let levelEl = document.querySelectorAll('.js-side-nav__level[data-side-nav-level="' + level + '"]')[0];

        on('click', function () {
            /* close side items */
            sideitemToggle.forEach(function (toggler) {
                removeClass('open', toggler);
            });
            sideItem.forEach(function (item) {
                removeClass('open', item);
            });

            //check if sidenavLevels has open class and remove it
            sidenavLevels.forEach(function (el) {
                if(hasClass('open', el)) {
                    removeClass('open', el);
                }
            });

            if(hasClass('has-openend-sidenav', link)) {
                removeClass('has-openend-sidenav', link);
                removeClass('open', sidenav);
                removeClass('open', mainNavToggler);
                removeClass('open', levelEl);
                removeClass('open', backdrop);
            } else {
                level1Link.forEach(function (el) {
                    if(hasClass('has-openend-sidenav', el)) {
                        removeClass('has-openend-sidenav', el);
                    }
                });
                addClass('has-openend-sidenav', link);
                addClass('open', sidenav);
                addClass('open', mainNavToggler);
                addClass('open', levelEl);
                addClass('open', backdrop);
            }

        }, link);
    });


    /* back link */
    onFind('.js-side-nav__back', function (link) {
        on('click', function () {
            let currentSidenav = closest('.js-side-nav__level', link);
            removeClass('open', currentSidenav);

            level1Link.forEach(function (el) {
                if(hasClass('has-openend-sidenav', el)) {
                    removeClass('has-openend-sidenav', el);
                }
            });
        }, link);
    });
}